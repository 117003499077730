import axios from "axios";
import { VIDEO_ACTIONS } from "../constants/action-types";

export const getAllVideo = () => {
  return async (dispatch) => {
    dispatch({ type: VIDEO_ACTIONS.VIDEOS_LOADING });
    await axios
      .get("/1.0.0/video", {})
      .then((response) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEOS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEOS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getOneVideo = (videoId) => {
  return async (dispatch) => {
    dispatch({ type: VIDEO_ACTIONS.VIDEOS_LOADING });
    await axios
      .get("/1.0.0/video/find/" + videoId, {})
      .then((response) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEOS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEOS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const uploadVideo = (file) => {
  return async (dispatch) => {
    dispatch({ type: VIDEO_ACTIONS.VIDEO_REGISTER_START });
    await axios
      .post("/1.0.0/video/create", file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEO_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEO_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyVideoName = (params) => {
  return async (dispatch) => {
    dispatch({ type: VIDEO_ACTIONS.VIDEO_REGISTER_START });
    await axios
      .put("/1.0.0/video/modify-name", params, {})
      .then((response) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEO_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEO_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const uploadCoverPicToVideo = (videoId, file) => {
  return async (dispatch) => {
    dispatch({ type: VIDEO_ACTIONS.VIDEO_REGISTER_START });
    await axios
      .put("/1.0.0/video/create-caverpic/" + videoId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEO_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEO_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const addVideo = (params) => {
  return async (dispatch) => {
    dispatch({ type: VIDEO_ACTIONS.VIDEO_ADD_REGISTER_START });
    await axios
      .post("/1.0.0/video/add-video", params, {})
      .then((response) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEO_ADD_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VIDEO_ACTIONS.VIDEO_ADD_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteVideo = (videoId) => {
  return async () => {
    await axios.delete("/1.0.0/video/" + videoId).catch((err) => {
      console.log(err.message);
    });
  };
};
