import { VIDEO_ACTIONS } from "../constants/action-types";

const initialState = {
  video: [],
  status: false,
  message: "",
  saveStatus: false,
  addSaveStatus: false
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_ACTIONS.VIDEOS_LOADING:
      return Object.assign({}, state, {
        video: [],
        status: false,
        message: "",
      });
    case VIDEO_ACTIONS.VIDEOS_LOADED:
      return Object.assign({}, state, {
        video: action.payload.video,
        status: true,
        message: "",
      });
    case VIDEO_ACTIONS.VIDEOS_ERROR:
      return Object.assign({}, state, {
        video: [],
        status: false,
        message: action.payload,
      });
    case VIDEO_ACTIONS.VIDEO_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case VIDEO_ACTIONS.VIDEO_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
        message: action.payload
      });
    case VIDEO_ACTIONS.VIDEO_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
      case VIDEO_ACTIONS.VIDEO_ADD_REGISTER_START:
      return Object.assign({}, state, {
        addSaveStatus: false,
      });
    case VIDEO_ACTIONS.VIDEO_ADD_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        addSaveStatus: true,
      });
    case VIDEO_ACTIONS.VIDEO_ADD_REGISTER_ERROR:
      return Object.assign({}, state, {
        addSaveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default videoReducer;
