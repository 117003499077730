import axios from "axios";
import { LOGIN_ACTIONS } from "../constants/action-types.js";
import HttpService from "../services/http";

export const login = (username, password) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.TOKEN_LOADING });
    await axios
      .post("/1.0.0/users/login", {
        username,
        password,
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_ERROR,
          payload: err.response?.data,
        });
        console.log(err.message);
      });
  };
};

export const refreshUser = (userId) => {
  return async (dispatch) => {
    await axios
      .get("/1.0.0/users/refresh-user", {
        params: { userId: userId },
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.LOGOUT });
  };
};

export const ssoLogin = () => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.SSO_RERIRECT_LOADING });
    await axios
      .get("/1.0.0/users/sso/post", {})
      .then((response) => {
        dispatch({
          type: LOGIN_ACTIONS.SSO_RERIRECT_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const ssoRedirect = (token) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.TOKEN_LOADING});
    await axios
      .get("/1.0.0/users/verify", { params: { token: token } })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_ERROR,
          payload: err.response?.data,
        });
        console.log(err.message);
      });
  };
};