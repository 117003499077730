import React, { Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from "./components/skeleton/main";
import { Helmet } from "react-helmet";
import axios from "axios";
import LoginRedirect from "./components/Login/loginRedirect";
import LoginMain from "./components/Login/loginMain";
import { Spin } from "antd";
import "./reset.css";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute/protected-route";
import { Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import SSOLogin from "./components/Login/ssologin";
import SSORedirect from "./components/Login/ssoRedirect";

const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";

  return (
    <Suspense fallback={<Spin spinning={true} />}>
      <div style={{ height: "100%" }}>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginMain} />
            <Route exact path="/sso/post" component={SSOLogin} />
            <Route exact path="/sso/redirect" component={SSORedirect} />
            <ProtectedRoute path="/mol" component={Main} />
            <Route exact path="/login-redirect" component={LoginRedirect} />
            <Redirect from="**" to="/login" />
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
};

export default App;
