import axios from "axios";
import { POSITION_ACTIONS } from "../constants/action-types";

export const getAllPosition = () => {
  return async (dispatch) => {
    dispatch({ type: POSITION_ACTIONS.POSITIONS_LOADING });
    await axios
      .get("/1.0.0/position", {})
      .then((response) => {
        dispatch({
          type: POSITION_ACTIONS.POSITIONS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POSITION_ACTIONS.POSITIONS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getOnePosition = (positionId) => {
  return async (dispatch) => {
    dispatch({ type: POSITION_ACTIONS.POSITIONS_LOADING });
    await axios
      .get("/1.0.0/position/find/" + positionId, {})
      .then((response) => {
        dispatch({
          type: POSITION_ACTIONS.POSITIONS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POSITION_ACTIONS.POSITIONS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const createPosition = (params) => {
  return async (dispatch) => {
    dispatch({ type: POSITION_ACTIONS.POSITION_REGISTER_START });
    await axios
      .post("/1.0.0/position/create", params, {})
      .then((response) => {
        dispatch({
          type: POSITION_ACTIONS.POSITION_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POSITION_ACTIONS.POSITION_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyPosition = (params) => {
  return async (dispatch) => {
    dispatch({ type: POSITION_ACTIONS.POSITION_REGISTER_START });
    await axios
      .put("/1.0.0/position/modify", params, {})
      .then((response) => {
        dispatch({
          type: POSITION_ACTIONS.POSITION_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POSITION_ACTIONS.POSITION_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deletePosition = (positionId) => {
  return async () => {
    await axios.delete("/1.0.0/position/" + positionId).catch((err) => {
      console.log(err.message);
    });
  };
};
