import React, { Component } from "react";
import Body from "./body";
import { Layout, Menu, Tag } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  BookOutlined,
  AimOutlined,
  CameraOutlined,
  VideoCameraAddOutlined,
  IdcardOutlined,
  VideoCameraOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import "./main.scss";
import { connect } from "react-redux";
import { logout } from "../../actions/login";
import { NavLink } from "react-router-dom";
import HttpService from "../../services/http";
import socketIOClient from "socket.io-client";
import md5 from "md5";
import { closeTag, openTagWithMessage } from "../../actions/tag";
import SubMenu from "antd/lib/menu/SubMenu";
const { Header, Sider, Content } = Layout;
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    closeTag: () => dispatch(closeTag()),
    openTagWithMessage: (messageToSend) =>
      dispatch(openTagWithMessage(messageToSend)),
  };
};

class ConnectedMain extends Component {
  state = {
    collapsed: false,
    selectedKey: "1",
    socket: null,
    snapped: true,
    height: window.innerHeight,
    width: window.innerWidth,
    openKeys: [],
  };

  constructor(props) {
    super(props);
    HttpService.setAxiosDefaults(props.token);
  }

  componentDidMount = async () => {
    this.setDefaultSelectedKeyForMenu();
    const socket = socketIOClient(`${process.env.REACT_APP_BASE_URL}`, {
      transports: ["websocket"],
      query: { token: this.props.token.split(" ")[1] },
      path: "/api/socket.io",
      secure: true,
    });
    socket.io.connect();

    window.addEventListener("resize", () => {
      this.setSnapLogoToMenuEnd();
    });
    window.addEventListener("change", () => {
      this.setSnapLogoToMenuEnd();
    });
    this.setSnapLogoToMenuEnd();
    if (this.props.apiKey) {
      const md5hash = md5(
        `${this.props.user}${this.props.apiKey}${this.props.user.username}`
      );
      this.setState({ md5hash: md5hash });
    }
  };

  setSnapLogoToMenuEnd = () => {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
    const menu = document.querySelector(".ant-menu");
    if (window.innerHeight - menu?.getBoundingClientRect().height <= 220) {
      this.setState({ snapped: false });
    } else {
      this.setState({ snapped: true });
    }
  };

  componentWillUnmount = () => {
    HttpService.clearToken();
    if (this.state.socket) {
      this.state.socket.disconnect();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      this.setDefaultSelectedKeyForMenu();
    }
    if (prevState.openKeys.length !== this.state.openKeys.length) {
      this.setSnapLogoToMenuEnd();
    }
  }

  setDefaultSelectedKeyForMenu = () => {
    switch (this.props.location.pathname) {
      case "/mol/layer":
        this.props.closeTag();
        this.setState({ selectedKey: "2" });
        break;
      case "/mol/poi":
        this.props.closeTag();
        this.setState({ selectedKey: "3" });
        break;
      case "/mol/media":
        this.props.closeTag();
        this.setState({ selectedKey: "4" });
        break;
      case "/mol/floating-board":
        this.props.closeTag();
        this.setState({ selectedKey: "5" });
        break;
      case "/mol/version-history":
        this.props.closeTag();
        this.setState({ selectedKey: "8" });
        break;
      default:
        this.props.closeTag();
        this.setState({ selectedKey: null });
        break;
    }
  };

  handleSelect = (event) => {
    this.setState({ selectedKey: event.key });
  };

  logout = async () => {
    await this.props.logout();
    this.props.history.push("/login");
  };

  handleMenuClick = async (event) => {
    switch (event.key) {
      case "logout":
        await this.logout();
        break;
      default:
        break;
    }
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  openKeys = (event) => {
    if (this.state.openKeys.length === 0) {
      this.setState({ openKeys: event });
    } else {
      const notInculded = event.filter(
        (element) => element !== this.state.openKeys[0]
      );

      this.setState({ openKeys: notInculded });
    }
    setTimeout(() => {
      this.setSnapLogoToMenuEnd();
    }, 150);
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Layout style={{ height: "100vh" }}>
          <Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            className={!this.state.collapsed && "sider"}
            style={{
              background: "#151917",
            }}
          >
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[this.state.selectedKey]}
              onSelect={this.handleSelect}
              style={{
                background: "#151917",
              }}
              openKeys={this.state.openKeys}
              onOpenChange={this.openKeys}
              subMenuOpenDelay={0}
              forceSubMenuRender
              onClick={this.handleMenuClick}
            >
              <Menu.Item key="5" icon={<IdcardOutlined />}>
                <NavLink to="/mol/floating-board" className="link-color">
                  Lebegő tábla
                </NavLink>
              </Menu.Item>
              <Menu.Item key="2" icon={<BookOutlined />}>
                <NavLink to="/mol/layer" className="link-color">
                  Layer
                </NavLink>
              </Menu.Item>
              <Menu.Item key="3" icon={<AimOutlined />}>
                <NavLink to="/mol/poi" className="link-color">
                  POI
                </NavLink>
              </Menu.Item>
              <SubMenu icon={<VideoCameraAddOutlined />} title="Média">
                <Menu.Item key="6" icon={<CameraOutlined />}>
                  <NavLink to="/mol/picture" className="link-color">
                    Képek
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="7" icon={<VideoCameraOutlined />}>
                  <NavLink to="/mol/video" className="link-color">
                    Videók
                  </NavLink>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="8" icon={<SnippetsOutlined />}>
                <NavLink to="/mol/version-history"> Verzió publikálás </NavLink>
              </Menu.Item>
              <Menu.Item key="logout" icon={<LogoutOutlined />}>
                Kijelentkezés
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: "0 0 0 26px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  {React.createElement(
                    this.state.collapsed
                      ? MenuUnfoldOutlined
                      : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: this.toggle,
                    }
                  )}
                </div>
              </div>
              <div
                style={{ width: "65vw", height: "auto", paddingTop: "15px" }}
              >
                <Tag
                  closable
                  onClose={this.props.closeTag}
                  visible={this.props.visible}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#ADADAD",
                    fontSize: "1rem",
                    maxWidth: "100%",
                    whiteSpace: "unset",
                    position: "relative",
                    paddingRight: "25px",
                  }}
                >
                  {this.props.message}
                </Tag>
              </div>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: "0px 0px",
                paddingLeft: 24,
              }}
            >
              <Body socket={this.state.socket} />
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  apiKey: state.loginReducer.apiKey,
  message: state.tagReducer.message,
  visible: state.tagReducer.visible,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ConnectedMain);
export default Main;
