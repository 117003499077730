import axios from "axios";
import { PICTURE_ACTIONS } from "../constants/action-types";

export const getAllPicture = () => {
  return async (dispatch) => {
    dispatch({ type: PICTURE_ACTIONS.PICTURES_LOADING });
    await axios
      .get("/1.0.0/picture", {})
      .then((response) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURES_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getOnePicture = (pictureId) => {
  return async (dispatch) => {
    dispatch({ type: PICTURE_ACTIONS.PICTURES_LOADING });
    await axios
      .get("/1.0.0/picture/find/" + pictureId, {})
      .then((response) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURES_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURES_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const createPicture = (param, isFBoard) => {
  console.log(isFBoard);
  return async (dispatch) => {
    dispatch({ type: PICTURE_ACTIONS.PICTURE_REGISTER_START });
    await axios
      .post("/1.0.0/picture/create", param, {
        headers: { "Content-Type": "multipart/form-data" },
        params: { isFBoard: isFBoard },
      })
      .then((response) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURE_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURE_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyPictureName = (params) => {
  return async (dispatch) => {
    dispatch({ type: PICTURE_ACTIONS.PICTURE_REGISTER_START });
    await axios
      .put("/1.0.0/picture/modify-name/", params, {})
      .then((response) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURE_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURE_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const addPicture = (params) => {
  return async (dispatch) => {
    dispatch({ type: PICTURE_ACTIONS.PICTURE_ADD_START });
    await axios
      .post("/1.0.0/picture/add", params, {})
      .then((response) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURE_ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PICTURE_ACTIONS.PICTURE_ADD_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deletePicture = (pictureId) => {
  return async () => {
    await axios.delete("/1.0.0/picture/one/" + pictureId).catch((err) => {
      console.log(err.message);
    });
  };
};

export const deletePictureFromPoiOrFloatingboard = (params) => {
  return async () => {
    await axios
      .delete("/1.0.0/picture/fromPoiOrFloatingBoard", params)
      .catch((err) => {
        console.log(err.message);
      });
  };
};
