import { PICTURE_ACTIONS } from "../constants/action-types";

const initialState = {
  picture: [],
  status: false,
  message: "",
  saveStatus: false,
  addStatus: false
};

const pictureReducer = (state = initialState, action) => {
  switch (action.type) {
    case PICTURE_ACTIONS.PICTURES_LOADING:
      return Object.assign({}, state, {
        picture: [],
        status: false,
        message: "",
      });
    case PICTURE_ACTIONS.PICTURES_LOADED:
      return Object.assign({}, state, {
        picture: action.payload.picture,
        status: true,
        message: "",
      });
    case PICTURE_ACTIONS.PICTURES_ERROR:
      return Object.assign({}, state, {
        picture: [],
        status: false,
        message: action.payload,
      });
    case PICTURE_ACTIONS.PICTURE_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case PICTURE_ACTIONS.PICTURE_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case PICTURE_ACTIONS.PICTURE_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
      case PICTURE_ACTIONS.PICTURE_ADD_START:
      return Object.assign({}, state, {
        addStatus: false,
      });
    case PICTURE_ACTIONS.PICTURE_ADD_SUCCESS:
      return Object.assign({}, state, {
        addStatus: true,
      });
    case PICTURE_ACTIONS.PICTURE_ADD_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        addStatus: action.payload,
      });
    default:
      return state;
  }
};

export default pictureReducer;
