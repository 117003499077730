import {
  message,
  Form,
  Modal,
  Switch,
  Col,
  Table,
  Row,
  Button,
  Input,
  Space,
  Popconfirm,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createFloatingBoard,
  deleteFloatingBoard,
  getAllFloatingBoard,
  getOneFloatingBoard,
  modifyFloatingBoard,
} from "../../actions/floatingBoard";
import { getAllPosition } from "../../actions/position";
import { EditOutlined, DeleteFilled } from "@ant-design/icons";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFloatingBoard: () => dispatch(getAllFloatingBoard()),
    getOneFloatingBoard: (fBoardId) => dispatch(getOneFloatingBoard(fBoardId)),
    createFloatingBoard: (params) => dispatch(createFloatingBoard(params)),
    modifyFloatingBoard: (params) => dispatch(modifyFloatingBoard(params)),
    deleteFloatingBoard: (fBoardId) => dispatch(deleteFloatingBoard(fBoardId)),
    getAllPosition: () => dispatch(getAllPosition()),
  };
};

class ConnectedFloatingBoard extends Component {
  formRef = React.createRef();
  state = {
    fBoards: [],
    name: "",
    active: false,
    statusId: null,
    positionId: null,
    modify: false,
    selectedId: null,
    positions: [],
    modalVisible: false,
    altitude: null,
    longitude: null,
    latitude: null,
    loaded: false,
  };
  componentDidMount = async () => {
    await this.getAllFloatingBoard();
    await this.getAllPositions();
  };

  getAllFloatingBoard = async () => {
    await this.props.getAllFloatingBoard();
    if (this.props.status) {
      this.setState({
        fBoards: this.props.floatingBoard,
        loaded: true,
      });
    } else {
      this.setState({
        loaded: false,
      });
    }
  };

  getAllPositions = async () => {
    await this.props.getAllPosition();
    if (this.props.positionStatus) {
      this.setState({
        positions: this.props.position,
        loaded: true,
      });
    } else {
      this.setState({
        loaded: false,
      });
    }
  };

  createFloatingBoard = async () => {
    const toSend = {
      id: this.state.modify ? this.state.selectedId : null,
      name: this.state.name,
      active: this.state.active,
      //////////////////////////////////////////////// HA EGYSZERRE AKARJUK A MÓDOSÍTÁSOKAT PUBLIKÁLNI AKKOR STATIKUSAN 2-es ARCHÍV STÁTUSZÚ AZ ÉRTÉK///////////////////////////////////////////////////////////////////
      statusID: 2 /*this.state.statusId*/,
      altitude: this.state.altitude,
      longitude: this.state.longitude,
      latitude: this.state.latitude,
    };
    this.state.modify
      ? await this.props.modifyFloatingBoard(toSend)
      : await this.props.createFloatingBoard(toSend);
    if (this.props.saveStatus) {
      message.success("Sikeres mentés");
      await this.getAllFloatingBoard();
      this.cancelModifyModal();
    } else {
      message.error("Hiba a mentés közben!");
    }
  };

  showModifyModal = async (record) => {
    console.log(record);
    this.setState({
      modify: true,
      selectedId: record.id,
      modalVisible: true,
      name: record.name,
      active: record.active,
      //////////////////////////////////////////////// HA EGYSZERRE AKARJUK A MÓDOSÍTÁSOKAT PUBLIKÁLNI AKKOR STATIKUSAN 2-es ARCHÍV STÁTUSZÚ AZ ÉRTÉK///////////////////////////////////////////////////////////////////
      statusId: 2 /*record.status?.id*/,
      altitude: record.position?.altitude,
      longitude: record.position?.longitude,
      latitude: record.position?.latitude,
    });
    this.formRef.current.setFieldsValue({
      name: record.name,
      active: record.active,
      statusId: record.status?.id === 1 ? "Publikált" : "Vázlat",
      altitude: record.position?.altitude,
      longitude: record.position?.longitude,
      latitude: record.position?.latitude,
    });
  };

  cancelModifyModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      modify: false,
      selectedId: null,
      modalVisible: false,
      name: "",
      index: null,
      active: false,
      isMain: false,
      isMol: false,
      languageID: null,
      statusID: null,
      modifyPic: false,
    });
  };
  deleteFloatingBoard = async (record) => {
    await this.props.deleteFloatingBoard(record.id);
    await this.getAllFloatingBoard();
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeActive = (event) => {
    this.setState({ active: event });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  onChangeStatusType = (event, record) => {
    this.setState({ statusId: event });
  };

  onChangePosition = (event, record) => {
    this.setState({ positionId: event });
  };

  render() {
    const columns = [
      {
        title: "Név",
        key: "layerName",
        dataIndex: "name",
      },
      // {
      //   title: "Szám",
      //   key: "layerName",
      //   dataIndex: "number",
      // },
      {
        title: "Állapot",
        key: "layerName",
        render: (text, record) => {
          return (
            <div
              style={{
                width: "1.1vw",
                height: "1.1vw",
                borderRadius: "50%",
                backgroundColor:
                  record.active === null
                    ? "gray"
                    : record.active === true
                    ? "green"
                    : "gray",
              }}
            />
          );
        },
      },
      {
        title: "Státusz",
        key: "status",
        render: (text, record) => {
          return record.status
            ? record?.status.name === "archived"
              ? "Vázlat"
              : "Publikált"
            : [];
        },
      },
      {
        title: "Módosítás",
        key: "modify",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => this.showModifyModal(record)}
                type="primary"
              >
                <EditOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: "Törlés",
        key: "delete",
        render: (text, record) => {
          return (
            <Popconfirm
              title="Biztosan törölni akarod ezt a lebegő táblát?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => this.deleteFloatingBoard(record)}
            >
              <Button danger type="primary">
                <DeleteFilled />
              </Button>
            </Popconfirm>
          );
        },
      },
    ];
    return (
      <div>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>Lebegő tábla</strong>
            </h1>
            <Row>
              <Row>
                <Col span={30} style={{ padding: "1em" }}>
                  <Button type="primary" onClick={this.showModal}>
                    Létrehozás
                  </Button>
                </Col>
              </Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  rowKey="id"
                  dataSource={this.state.fBoards}
                  locale="Nem található tartalom!"
                  loading={!this.state.loaded}
                  pagination={{
                    position: ["bottomCenter"],
                    pageSize: 10,
                    showSizeChanger: false,
                  }}
                  onChange={this.handleChangeTable}
                />
              </Col>
            </Row>
            <Modal
              visible={this.state.modalVisible}
              onCancel={this.cancelModifyModal}
              title={this.state.modify ? "Módosítás" : "Létrehozás"}
              maskClosable={false}
              forceRender
              centered
              footer={[
                <Button
                  key="invite"
                  type="primary"
                  onClick={this.createFloatingBoard}
                >
                  {this.state.modify ? "Módosítás" : "Létrehozás"}
                </Button>,
                <Button key="cancel" onClick={this.cancelModifyModal}>
                  Mégsem
                </Button>,
              ]}
              width={"55em"}
            >
              <Row>
                <Col span={24} style={{ padding: "1em" }}>
                  <Form
                    ref={this.formRef}
                    name="register"
                    onFinish={this.createFloatingBoard}
                    scrollToFirstError
                  >
                    <Row>
                      <Col span={12} style={{ padding: "0.5em" }}>
                        <Tooltip title="Max 255 karakter!">
                          <Form.Item
                            name="name"
                            label="Név"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                            ]}
                          >
                            <Input
                              name="name"
                              value={this.state.name}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col span={4} style={{ padding: "0.5em" }}>
                        <Form.Item
                          name="active"
                          label="Aktív"
                          rules={[
                            {
                              required: true,
                              message: "Mező kitöltése kötelező!",
                            },
                          ]}
                        >
                          <Switch
                            name="active"
                            checked={this.state.active}
                            onChange={this.handleChangeActive}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row></Row>
                    <Row>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Tooltip title="Csak szám!">
                          <Form.Item
                            name="altitude"
                            label=" Magasság"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                              {
                                pattern: /^\d+(\.\d+)*$/,
                                message: "Csak számok elfogadottak",
                              },
                            ]}
                          >
                            <Input
                              name="altitude"
                              value={this.state.altitude}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Tooltip title="Csak szám!">
                          <Form.Item
                            name="longitude"
                            label="Hosszúság"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                              {
                                pattern: /^\d+(\.\d+)*$/,
                                message: "Csak számok elfogadottak",
                              },
                            ]}
                          >
                            <Input
                              name="longitude"
                              value={this.state.longitude}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Tooltip title="Csak szám!">
                          <Form.Item
                            name="latitude"
                            label="Szélesség"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                              {
                                pattern: /^\d+(\.\d+)*$/,
                                message: "Csak számok elfogadottak",
                              },
                            ]}
                          >
                            <Input
                              name="latitude"
                              value={this.state.latitude}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  floatingBoard: state.floatingBoardsReducer.floatingBoard,
  status: state.floatingBoardsReducer.status,
  saveStatus: state.floatingBoardsReducer.saveStatus,
  positionStatus: state.positionReducer.status,
  position: state.positionReducer.position,
});

const FloatingBoard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedFloatingBoard);
export default FloatingBoard;
