import { TAG_ACTIONS } from "../constants/action-types";

const initialState = {
  visible: false,
  message: "",
};

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case TAG_ACTIONS.OPEN_WITH_MESSAGE:
      return Object.assign({}, state, {
        visible: true,
        message: action.payload,
      });
    case TAG_ACTIONS.CLOSE:
      return Object.assign({}, state, {
        visible: false,
        message: "",
      });

    default:
      return state;
  }
};

export default tagReducer;
