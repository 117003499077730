import { Tabs } from "antd";
import React, { Component } from "react";
import Login from "./login";
import logo from "../../static/logo.png";
import "./login.scss";

class ConnectedLoginMain extends Component {
  render() {
    return (
      <div style={{ paddingTop: "100px" }}>
        <div style={{ margin: "0 auto", width: "20%" }}>
          <div className="login-page-icon-container">
            <img src={logo} alt="logo" className="login-page-icon" />
          </div>
          <Tabs defaultActiveKey="1">
            <Login history={this.props.history} />
          </Tabs>
        </div>

        <div
          style={{
            margin: "2em auto",
            width: "20%",
            textAlign: "center",
            bottom: "0",
          }}
        >
          <p>Minden jog fenntartva! &#169; 2023 MOL</p>
        </div>
      </div>
    );
  }
}

export default ConnectedLoginMain;
