import {
  message,
  Row,
  Col,
  Button,
  Table,
  Modal,
  Form,
  Input,
  Select,
  Space,
  Popconfirm,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createPoi, deletePoi, getAllPoi, modifyPoi } from "../../actions/poi";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import { getAllLayer } from "../../actions/layer";
const mapDispatchToProps = (dispatch) => {
  return {
    getAllPOI: () => dispatch(getAllPoi()),
    createPOI: (params) => dispatch(createPoi(params)),
    modifyPOI: (params) => dispatch(modifyPoi(params)),
    deletePOI: (poiId) => dispatch(deletePoi(poiId)),
    getAllLayer: () => dispatch(getAllLayer()),
  };
};

class ConnectedPOI extends Component {
  formRef = React.createRef();
  state = {
    poi: [],
    name: "",
    engName: "",
    description: "",
    statusId: null,
    layerId: null,
    direction: null,
    selectedId: null,
    modify: false,
    engDescription: "",
    selectedPoi: "",
    huDescriptionId: null,
    engDescriptionId: null,
    huPoiNameId: null,
    engPoiNameId: null,
    loaded: false,
    layer: [],
  };
  componentDidMount = async () => {
    await this.getAllPOI();
    await this.getAllLayer();
  };

  getAllPOI = async () => {
    await this.props.getAllPOI();
    this.setState({
      loaded: false,
    });
    if (this.props.status) {
      this.setState({
        poi: this.props.poi,
        loaded: true,
      });
    }
  };

  getAllLayer = async () => {
    await this.props.getAllLayer();
    this.setState({
      loaded: false,
    });
    if (this.props.layerStatus) {
      this.setState({
        layer: this.props.layer,
        loaded: true,
      });
    }
  };

  createPOI = async () => {
    const toSend = {
      name: this.state.name,
      engName: this.state.engName,
      description: this.state.description,
      engDescription: this.state.engDescription,
      direction: this.state.direction,
      altitude: this.state.altitude,
      longitude: this.state.longitude,
      latitude: this.state.latitude,
      id: this.state.modify ? this.state.selectedId : null,
      //////////////////////////////////////////////// HA EGYSZERRE AKARJUK A MÓDOSÍTÁSOKAT PUBLIKÁLNI AKKOR STATIKUSAN 2-es ARCHÍV STÁTUSZÚ AZ ÉRTÉK///////////////////////////////////////////////////////////////////
      statusID: 2 /*this.state.statusId*/,
      layerID: this.state.layerId,
      huDescriptionId: this.state.huDescriptionId,
      engDescriptionId: this.state.engDescriptionId,
      huPoiNameId: this.state.huPoiNameId,
      engPoiNameId: this.state.engPoiNameId,
    };
    this.state.modify
      ? await this.props.modifyPOI(toSend)
      : await this.props.createPOI(toSend);
    if (this.props.saveStatus) {
      message.success("Sikeres mentés");
      await this.getAllPOI();
      this.cancelModifyModal();
    } else {
      message.error("Hiba a poi mentése közben");
    }
  };

  deletePOI = async (record) => {
    await this.props.deletePOI(record.id);
    await this.getAllPOI();
  };

  showModifyModal = async (record) => {
    const layer = this.state.layer.filter(
      (layer) => layer.id === record.layer.id
    );

    this.setState({
      modify: true,
      selectedId: record.id,
      modalVisible: true,
      name: record.poiName[0]?.name,
      engName: record.poiName[1]?.name,
      description: record.description[0].description,
      engDescription: record.description[1].description,
      direction: record.position?.direction,
      //////////////////////////////////////////////// HA EGYSZERRE AKARJUK A MÓDOSÍTÁSOKAT PUBLIKÁLNI AKKOR STATIKUSAN 2-es ARCHÍV STÁTUSZÚ AZ ÉRTÉK///////////////////////////////////////////////////////////////////
      statusId: 2 /*record.status.id*/,
      layerId: record.layer.id,
      altitude: record.position?.altitude,
      longitude: record.position.longitude,
      latitude: record.position.latitude,
      huDescriptionId: record.description[0].id,
      engDescriptionId: record.description[1].id,
      huPoiNameId: record.poiName[0].id,
      engPoiNameId: record.poiName[1].id,
    });
    this.formRef.current.setFieldsValue({
      name: record.poiName[0]?.name,
      engName: record.poiName[1]?.name,
      description: record.description[0].description,
      engDescription: record.description[1].description,
      direction: record.position?.direction === 0 ? "Jobbról" : "Balról",
      altitude: record.position?.altitude,
      longitude: record.position.longitude,
      latitude: record.position.latitude,
      statusId: record.status.name,
      layerId: layer[0]?.name[0]?.name,
    });
  };

  cancelModifyModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      modify: false,
      selectedId: null,
      modalVisible: false,
      name: "",
      engName: "",
      description: "",
      engDescription: "",
      direction: "",
      statusId: null,
      layerId: null,
      altitude: null,
      longitude: null,
      latitude: null,
      huDescriptionId: null,
      engDescriptionId: null,
    });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  onChangeStatusType = (event, record) => {
    this.setState({ statusId: event });
  };

  onChangeDirectionType = (event) => {
    this.setState({ direction: event });
  };

  onChangeLayer = (event) => {
    this.setState({ layerId: event });
  };

  render() {
    const columns = [
      {
        title: "Név",
        key: "name",
        width: "15%",
        render: (text, record) => {
          return record?.poiName ? record?.poiName[0]?.name : [];
        },
      },
      {
        title: "Leírás",
        key: "description",
        width: "20%",
        render: (text, record) => {
          return record.description ? record?.description[0]?.description : [];
        },
      },
      {
        title: "Megközelítés",
        key: "direction",
        render: (text, record) => {
          return record.position?.direction === 0 ? "Jobb" : "Bal";
        },
      },
      {
        title: "Státusz",
        key: "status",
        render: (text, record) => {
          return record.status
            ? record?.status.name === "archived"
              ? "Vázlat"
              : "Publikált"
            : [];
        },
      },
      {
        title: "Módosítás",
        key: "modify",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => this.showModifyModal(record)}
                type="primary"
              >
                <EditOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: "Törlés",
        key: "delete",
        render: (text, record) => {
          return (
            <Popconfirm
              title="Biztosan törölni akarod ezt a poit?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => this.deletePOI(record)}
            >
              <Button danger type="primary">
                <DeleteFilled />
              </Button>
            </Popconfirm>
          );
        },
      },
    ];
    return (
      <div>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>POI</strong>
            </h1>
            <Row>
              <Row>
                <Col span={30} style={{ padding: "1em" }}>
                  <Button type="primary" onClick={this.showModal}>
                    Létrehozás
                  </Button>
                </Col>
              </Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  rowKey="id"
                  dataSource={this.state.poi}
                  locale="Nem található tartalom!"
                  loading={!this.state.loaded}
                  pagination={{
                    position: ["bottomCenter"],
                    pageSize: 10,
                    showSizeChanger: false,
                  }}
                  onChange={this.handleChangeTable}
                />
              </Col>
            </Row>
            <Modal
              visible={this.state.modalVisible}
              onCancel={this.cancelModifyModal}
              title={this.state.modify ? "Módosítás" : "Létrehozás"}
              maskClosable={false}
              forceRender
              centered
              footer={[
                <Button key="invite" type="primary" onClick={this.createPOI}>
                  {this.state.modify ? "Módosítás" : "Létrehozás"}
                </Button>,
                <Button key="cancel" onClick={this.cancelModifyModal}>
                  Mégsem
                </Button>,
              ]}
              width={"75em"}
            >
              <Row>
                <Col span={24} style={{ padding: "1em" }}>
                  <Form
                    ref={this.formRef}
                    name="register"
                    onFinish={this.createPOI}
                    scrollToFirstError
                  >
                    <Row>
                      <Col span={12} style={{ padding: "0.5em" }}>
                        <Tooltip title="Max 255 karakter!">
                          <Form.Item
                            name="name"
                            label="Magyar név"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                            ]}
                          >
                            <Input
                              name="name"
                              value={this.state.name}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col span={12} style={{ padding: "0.5em" }}>
                        <Tooltip title="Max 255 karakter!">
                          <Form.Item
                            name="engName"
                            label="Angol név"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                            ]}
                          >
                            <Input
                              name="engName"
                              value={this.state.engName}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} style={{ padding: "0.5em" }}>
                        <Form.Item
                          name="description"
                          label="Magyar leírás"
                          rules={[
                            {
                              required: true,
                              message: "Mező kitöltése kötelező!",
                            },
                          ]}
                        >
                          <Input.TextArea
                            name="description"
                            value={this.state.description}
                            onChange={this.handleChange}
                            rows={4}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} style={{ padding: "0.5em" }}>
                        <Form.Item
                          name="engDescription"
                          label="Angol leírás"
                          rules={[
                            {
                              required: true,
                              message: "Mező kitöltése kötelező!",
                            },
                          ]}
                        >
                          <Input.TextArea
                            name="engDescription"
                            value={this.state.engDescription}
                            onChange={this.handleChange}
                            rows={4}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Form.Item
                          name="direction"
                          label="Megközelítés"
                          rules={[
                            {
                              required: true,
                              message: "Mező kitöltése kötelező!",
                            },
                          ]}
                        >
                          <Select
                            value={this.state.direction}
                            onChange={(event) =>
                              this.onChangeDirectionType(event)
                            }
                          >
                            <Select.Option key={0} value={0}>
                              Jobbról
                            </Select.Option>
                            <Select.Option key={1} value={1}>
                              Balról
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Form.Item
                          name="layerId"
                          label="Layer"
                          rules={[
                            {
                              required: true,
                              message: "Mező kitöltése kötelező!",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "12em" }}
                            value={this.state.layerId}
                            onChange={(event) => this.onChangeLayer(event)}
                          >
                            {this.state.layer?.map((layer) => {
                              return (
                                <Select.Option key={layer.id} value={layer.id}>
                                  {layer?.name[0]?.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Tooltip title="Csak szám!">
                          <Form.Item
                            name="altitude"
                            label=" Magasság"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                              {
                                pattern: /^\d+(\.\d+)*$/,
                                message: "Csak számok elfogadottak",
                              },
                            ]}
                          >
                            <Input
                              name="altitude"
                              value={this.state.altitude}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Tooltip title="Csak szám!">
                          <Form.Item
                            name="longitude"
                            label="Hosszúság"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                              {
                                pattern: /^\d+(\.\d+)*$/,
                                message: "Csak számok elfogadottak",
                              },
                            ]}
                          >
                            <Input
                              name="longitude"
                              value={this.state.longitude}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Tooltip title="Csak szám!">
                          <Form.Item
                            name="latitude"
                            label="Szélesség"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                              {
                                pattern: /^\d+(\.\d+)*$/,
                                message: "Csak számok elfogadottak",
                              },
                            ]}
                          >
                            <Input
                              name="latitude"
                              value={this.state.latitude}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poi: state.poiReducer.poi,
  status: state.poiReducer.status,
  saveStatus: state.poiReducer.saveStatus,
  layer: state.layerReducer.layers,
  layerStatus: state.layerReducer.status,
});

const Poi = connect(mapStateToProps, mapDispatchToProps)(ConnectedPOI);
export default Poi;
