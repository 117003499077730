import { FLOATING_BOARD_ACTIONS } from "../constants/action-types";

const initialState = {
  floatingBoard: [],
  status: false,
  message: "",
  saveStatus: false,
};

const floatingBoardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FLOATING_BOARD_ACTIONS.FLOATING_BOARDS_LOADING:
      return Object.assign({}, state, {
        floatingBoard: [],
        status: false,
        message: "",
      });
    case FLOATING_BOARD_ACTIONS.FLOATING_BOARDS_LOADED:
      return Object.assign({}, state, {
        floatingBoard: action.payload.floatingBoard,
        status: true,
        message: "",
      });
    case FLOATING_BOARD_ACTIONS.FLOATING_BOARDS_ERROR:
      return Object.assign({}, state, {
        floatingBoard: [],
        status: false,
        message: action.payload,
      });
    case FLOATING_BOARD_ACTIONS.FLOATING_BOARD_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case FLOATING_BOARD_ACTIONS.FLOATING_BOARD_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case FLOATING_BOARD_ACTIONS.FLOATING_BOARD_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default floatingBoardsReducer;
