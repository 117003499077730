import axios from "axios";
import { VERSION_ACTIONS } from "../constants/action-types";

export const updateVersion = () => {
  return async (dispatch) => {
    dispatch({ type: VERSION_ACTIONS.VERSION_REGISTER_START });
    await axios
      .post("/1.0.0/zip/version-update", {})
      .then((response) => {
        dispatch({
          type: VERSION_ACTIONS.VERSION_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VERSION_ACTIONS.VERSION_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getAllVersion = () => {
  return async (dispatch) => {
    dispatch({ type: VERSION_ACTIONS.VERSION_LOADING });
    await axios
      .get("/1.0.0/zip/", {})
      .then((response) => {
        dispatch({
          type: VERSION_ACTIONS.VERSION_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VERSION_ACTIONS.VERSION_ERROR,
          payload: err.response.data,
        });
      });
  };
};
