import React, { Component } from "react";

export default class MissingRight extends Component {
  render() {
    return (
      <div>
        <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
          <strong>Nincs joga ehhez a művelethez!</strong>
        </h1>
      </div>
    );
  }
}
