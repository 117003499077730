import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

class ConnectedProtectedRoute extends Component {
  isAuth = () => {
    if (this.props.token !== null) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.isAuth() ? (
          <Route
            path={this.props.path}
            render={(props) =>
              React.createElement(this.props.component, {
                ...props,
                socket: this.props.socket,
              })
            }
          />
        ) : (
          <Redirect from="*" to="/login" />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
});

const ProtectedRoute = connect(mapStateToProps, null)(ConnectedProtectedRoute);
export default ProtectedRoute;
