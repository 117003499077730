import React, { Component } from "react";
import { Button, Col, Row, message, Table } from "antd";
import { connect } from "react-redux";
import { getAllVersion, updateVersion } from "../../actions/version";
import moment from "moment";
const mapDispatchToProps = (dispatch) => {
  return {
    updateVersion: () => dispatch(updateVersion()),
    getAllVersion: () => dispatch(getAllVersion()),
  };
};

class ConnectedVersion extends Component {
  state = {
    versions: [],
    loaded: false,
  };

  componentDidMount = async () => {
    await this.getAllVersion();
  };

  getAllVersion = async () => {
    await this.props.getAllVersion();
    if (this.props.status) {
      this.setState({
        versions: this.props.versions,
        loaded: true,
      });
    }
  };
  update = async () => {
    await this.props.updateVersion();
    if (this.props.versionUpdateStatus) {
      message.success("Sikeres publikáció");
      await this.getAllVersion();
    } else {
      message.error("Hiba a publikáció során");
    }
  };

  render() {
    const columns = [
      {
        title: "Verziószám",
        key: "version",
        dataIndex: "version",
      },
      {
        title: "Létrehozás dátuma",
        key: "created",
        render: (text, record) => {
          return moment.unix(record.created).format("YYYY-MM-DD HH:mm");
        },
      },
    ];
    return (
      <div>
        <Row>
          <Col span={18} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>Verzió</strong>
            </h1>
          </Col>
          <Col>
            <Button
              type="primary"
              danger
              style={{
                position: "relative",
                top: "12em",
                right: "10em",
                width: "10em",
                height: "4em",
              }}
              onClick={() => this.update()}
            >
              Publikálás
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={8} style={{ padding: "1em", marginLeft: "1em" }}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={this.state.versions}
              locale="Nem található tartalom!"
              loading={!this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  versions: state.versionReducer.versions,
  status: state.versionReducer.status,
  versionUpdateStatus: state.versionReducer.saveStatus,
});

const Version = connect(mapStateToProps, mapDispatchToProps)(ConnectedVersion);
export default Version;
