import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import loginReducer from "../reducers/login";
import usersReducer from "../reducers/users";
import tagReducer from "../reducers/tag";
import { LOGIN_ACTIONS } from "../constants/action-types";
import floatingBoardsReducer from "../reducers/floatingBoard";
import layerReducer from "../reducers/layer";
import pictureReducer from "../reducers/picture";
import poiReducer from "../reducers/poi";
import positionReducer from "../reducers/position";
import videoReducer from "../reducers/video";
import versionReducer from "../reducers/version";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "usersReducer",
    "tagReducer",
    "floatingBoardsReducer",
    "layerReducer",
    "pictureReducer",
    "poiReducer",
    "positionReducer",
    "videoReducer",
    "versionReducer",
  ],
};

const rootReducer = (state, action) => {
  if (action.type === LOGIN_ACTIONS.LOGOUT) {
    state.usersReducer = undefined;
    state.tagReducer = undefined;
    state.floatingBoardsReducer = undefined;
    state.layerReducer = undefined;
    state.pictureReducer = undefined;
    state.poiReducer = undefined;
    state.positionReducer = undefined;
    state.videoReducer = undefined;
    state.versionReducer = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  loginReducer,
  usersReducer,
  tagReducer,
  floatingBoardsReducer,
  layerReducer,
  pictureReducer,
  poiReducer,
  positionReducer,
  videoReducer,
  versionReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
