import {
  message,
  Card,
  Row,
  Col,
  Button,
  Popconfirm,
  Form,
  Modal,
  Input,
  Select,
  Tooltip,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllPicture,
  createPicture,
  deletePicture,
  addPicture,
  modifyPictureName,
  getOnePicture,
  deletePictureFromPoiOrFloatingboard,
} from "../../actions/picture";
import { DeleteOutlined, EditOutlined, InboxOutlined } from "@ant-design/icons";
import { getAllPoi } from "../../actions/poi";
import { getAllFloatingBoard } from "../../actions/floatingBoard";
const { Meta } = Card;
const mapDispatchToProps = (dispatch) => {
  return {
    getAllPictures: () => dispatch(getAllPicture()),
    createPicture: (param, isFBoard) =>
      dispatch(createPicture(param, isFBoard)),
    addPicture: (params) => dispatch(addPicture(params)),
    deletePicture: (pictureId) => dispatch(deletePicture(pictureId)),
    modifyPictureName: (params) => dispatch(modifyPictureName(params)),
    getAllPoi: () => dispatch(getAllPoi()),
    getAllFloatingBoard: () => dispatch(getAllFloatingBoard()),
    getOnePicture: (pictureId) => getOnePicture(pictureId),
    deletePictureFromPoiOrFloatingBoard: (params) =>
      deletePictureFromPoiOrFloatingboard(params),
  };
};

class ConnectedPicture extends Component {
  formRef = React.createRef();
  state = {
    pictures: [],
    file: [],
    name: "",
    selectedId: null,
    modalVisible: false,
    modify: false,
    poiId: null,
    fBoardId: null,
    decider: null,
    isFBoard: false,
    isFBoardImage: false,
  };

  componentDidMount = async () => {
    await this.getPictures();
    await this.props.getAllPoi();
    await this.props.getAllFloatingBoard();
  };

  getPictures = async () => {
    await this.props.getAllPictures();
    if (this.props.status) {
      this.setState({
        pictures: this.props.pictures,
      });
    }
  };

  createPicture = async () => {
    const formData = new FormData();
    if (this.state.file.length > 0) {
      formData.append("file", this.state.file[0]);
    }
    await this.props.createPicture(formData, this.state.isFBoard);
    if (this.props.saveStatus) {
      message.success("Sikeres mentés");
      await this.getPictures();
      this.closeModal();
    } else {
      message.error("Hiba a kép mentése közben!");
    }
  };

  deletePicture = async (record) => {
    await this.props.deletePicture(record.id);
    await this.getPictures();
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  showModifyModal = async (record) => {
    const image = new Image();
    image.src = `${
      process.env.REACT_APP_BASE_URL +
      "/api/1.0.0/picture/getall/" +
      record.name
    }`;
    image.onload = () => {
      if (
        (image.height === 765 && image.width === 210) ||
        (image.width === 765 && image.height === 210)
      ) {
        this.setState({
          isFBoardImage: true,
        });
      }
    };
    const poiNames = this.props.poi.filter((poi) =>
      poi.id === record.poi?.id ? poi.poiName[0] : ""
    );
    this.setState({
      modalVisible: true,
      modify: true,
      selectedId: record.id,
      name: record?.alt,
      poiId: record.poi?.id,
      fBoardId: record.floatingBoard?.id,
    });
    this.formRef.current.setFieldsValue({
      name: record?.alt,
      poiId: poiNames[0]?.poiName[0]?.name,
      fBoardId: record.floatingBoard?.name,
    });
  };

  closeModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      modalVisible: false,
      modify: false,
      selectedId: null,
      name: "",
      poiId: null,
      fBoardId: null,
      decider: null,
      isFBoard: false,
      isFBoardImage: false,
    });
    this.removeFile();
  };

  renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <Card
          style={{ width: "15em", height: "25em", margin: "0.5em" }}
          cover={<img alt={photo.name} src={photo.url} />}
          actions={[
            <EditOutlined
              key="edit"
              onClick={() => this.showModifyModal(photo)}
            />,
            <Popconfirm
              title="Biztosan törölni akarod ezt a képet?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => this.deletePicture(photo)}
            >
              <DeleteOutlined key="delete" />,
            </Popconfirm>,
          ]}
        >
          <Meta title={photo.alt} />
        </Card>
      );
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  beforeUpload = async (file) => {
    if (file.size / 1024 / 1024 > 5) {
      this.removeFile();
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (this.state.decider === 0) {
            if (height > 1920 || width > 1920) {
              message.error("A kép felbontása túl nagy, max fullHD");
              this.removeFile();
            } else {
              this.setState({ file: [file] });
            }
          } else {
            if (
              (height === 765 && width === 210) ||
              (width === 765 && height === 210)
            ) {
              this.setState({ isFBoard: true });
              this.setState({ file: [file] });
            } else {
              message.error(
                "A kép felbontása túl nagy, 765x210 felbontás szükséges"
              );
              this.removeFile();
            }
          }
        };
      };
    }
    return false;
  };

  onChangePOI = (event, record) => {
    this.setState({ poiId: event });
  };

  onChangeFboard = (event, record) => {
    this.setState({ fBoardId: event });
  };

  modifyPicture = async () => {
    let close = false;
    const modify = {
      id: this.state.selectedId,
      name: this.state.name,
    };
    await this.props.modifyPictureName(modify);
    if (this.props.saveStatus) {
      message.success("Kép neve módosításra kerüt");
      close = true;
      if (this.state.fBoardId || this.state.poiId) {
        const toSend = {
          pictureID: this.state.selectedId,
          poiID: this.state.poiId,
          fBoardID: this.state.fBoardId,
        };
        await this.props.addPicture(toSend);
        if (this.props.addStatus) {
          message.success("Sikeres mentés");
          close = true;
        } else {
          close = false;
          message.error("Hiba a mentés közben");
        }
      }
      if (close) {
        await this.getPictures();
        this.closeModal();
      }
    } else {
      message.error("Hiba a kép nevének mentése közben");
    }
  };

  handleChangePoi = (event) => {
    this.setState({
      poiId: event,
    });
  };

  handleChangeFloatingBoard = (event) => {
    this.setState({
      fBoardId: event,
    });
  };

  handleChangeDecider = (event) => {
    this.setState({
      decider: event,
    });
    this.removeFile();
  };

  render() {
    return (
      <>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>Képek</strong>
            </h1>
            <Row>
              <Row>
                <Col span={30} style={{ padding: "1em" }}>
                  <Button type="primary" onClick={this.showModal}>
                    Létrehozás
                  </Button>
                </Col>
              </Row>
            </Row>
            <Row>{this.renderPhotos(this.state.pictures)}</Row>
          </Col>
        </Row>
        <Modal
          visible={this.state.modalVisible}
          onCancel={this.closeModal}
          title={this.state.modify ? "Módosítás" : "Létrehozás"}
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Button
              key="createPic"
              type="primary"
              disabled={this.state.file.length === 0 && !this.state.modify}
              onClick={
                this.state.modify ? this.modifyPicture : this.createPicture
              }
            >
              {this.state.modify ? "Módosítás" : "Létrehozás"}
            </Button>,
            <Button key="cancel" onClick={this.closeModal}>
              Mégsem
            </Button>,
          ]}
          width={"45em"}
        >
          <Row>
            <Col span={24} style={{ padding: "1em" }}>
              <Form
                ref={this.formRef}
                name="register"
                onFinish={
                  this.state.modify ? this.modifyPicture : this.createPicture
                }
                scrollToFirstError
              >
                {!this.state.modify ? (
                  <Row>
                    Válassza ki a feltölteni kívánt kép típusát:&nbsp;
                    <Select
                      style={{ width: "10em" }}
                      value={this.state.decider}
                      onChange={(event) => this.handleChangeDecider(event)}
                    >
                      <Select.Option key={0} value={0}>
                        Poi kép
                      </Select.Option>
                      <Select.Option key={1} value={1}>
                        Lebegő tábla kép
                      </Select.Option>
                    </Select>
                    <Row></Row>
                    <Col span={6} style={{ padding: "0.5em" }}></Col>
                    <Col
                      span={18}
                      style={{ padding: "0.5em", paddingLeft: "10em" }}
                    >
                      {this.state.decider === 0 ? (
                        <Tooltip title="Max FullHD és 5MB-nál kisebb jpg|jpeg|png|gif|PNG|JPG|JPEG formátumú file!">
                          <Form.Item
                            name="file"
                            rules={[
                              {
                                required: true,
                                message: "Kérem adjon meg egy fájlt!",
                              },
                            ]}
                          >
                            <Dragger
                              name="file"
                              multiple={false}
                              onRemove={this.removeFile}
                              accept=".png,.jpg,.jpeg,.gif"
                              beforeUpload={this.beforeUpload}
                              fileList={this.state.file}
                            >
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">Képfeltöltés</p>
                            </Dragger>
                          </Form.Item>
                        </Tooltip>
                      ) : this.state.decider === 1 ? (
                        <Tooltip title="Max felbontás 765x210 és 5MB-nál kisebb jpg|jpeg|png|gif|PNG|JPG|JPEG formátumú file!">
                          <Form.Item
                            name="file"
                            rules={[
                              {
                                required: true,
                                message: "Kérem adjon meg egy fájlt!",
                              },
                            ]}
                          >
                            <Dragger
                              name="file"
                              multiple={false}
                              onRemove={this.removeFile}
                              accept=".png,.jpg,.jpeg,.gif"
                              beforeUpload={this.beforeUpload}
                              fileList={this.state.file}
                            >
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">Képfeltöltés</p>
                            </Dragger>
                          </Form.Item>
                        </Tooltip>
                      ) : (
                        []
                      )}
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col span={4} style={{ padding: "0.5em" }}></Col>
                      <Col span={16} style={{ padding: "0.5em" }}>
                        <Tooltip title="Max 255 karakter!">
                          <Form.Item
                            name="name"
                            label="Név"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                            ]}
                          >
                            <Input
                              style={{ maxWidth: "20em" }}
                              name="name"
                              value={this.state.name}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row>
                      <div style={{ paddingLeft: "8em" }}>
                        {(this.state.fBoardId === undefined ||
                          this.state.fBoardId === null ||
                          this.state.fBoardId === 9999999999999) &&
                          this.state.isFBoardImage === false && (
                            <Form.Item label="Poi" name="poiId">
                              <Select
                                style={{ width: "10em" }}
                                value={this.state.poiId}
                                onChange={(event) =>
                                  this.handleChangePoi(event)
                                }
                              >
                                <Select.Option
                                  key={9999999999999}
                                  value={9999999999999}
                                >
                                  Egyik sem
                                </Select.Option>
                                {this.props.poi?.map((poi) => {
                                  return (
                                    <Select.Option key={poi.id} value={poi.id}>
                                      {poi.poiName[0]?.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          )}
                        {(this.state.poiId === undefined ||
                          this.state.poiId === null ||
                          this.state.poiId === 9999999999999) &&
                          this.state.isFBoardImage === true && (
                            <Form.Item label="Lebegő tábla" name="fBoardId">
                              <Select
                                style={{ width: "10em" }}
                                value={this.state.fBoardId}
                                onChange={(event) =>
                                  this.handleChangeFloatingBoard(event)
                                }
                              >
                                <Select.Option
                                  key={9999999999999}
                                  value={9999999999999}
                                >
                                  Egyik sem
                                </Select.Option>
                                {this.props.floatingBoard?.map(
                                  (floatingBoard) => {
                                    return (
                                      <>
                                        <Select.Option
                                          key={floatingBoard.id}
                                          value={floatingBoard.id}
                                        >
                                          {floatingBoard.name}
                                        </Select.Option>
                                      </>
                                    );
                                  }
                                )}
                              </Select>
                            </Form.Item>
                          )}
                      </div>
                    </Row>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pictures: state.pictureReducer.picture,
  status: state.pictureReducer.status,
  saveStatus: state.pictureReducer.saveStatus,
  addStatus: state.pictureReducer.addStatus,
  poi: state.poiReducer.poi,
  floatingBoard: state.floatingBoardsReducer.floatingBoard,
});

const Picture = connect(mapStateToProps, mapDispatchToProps)(ConnectedPicture);
export default Picture;
