import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "antd";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedLoginRedirect extends Component {
  componentDidMount = async () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const token = queryParams.get("token");
    if (token) {
      if (this.props.status) {
        this.props.history.push("/mol");
      } else {
        this.props.history.push("/");
        message.error("Sikertelen átirányítás", 5);
      }
    } else {
      this.props.history.push("/");
      message.error("Sikertelen átirányítás", 5);
    }
  };

  render() {
    return (
      <div>
        <div>Átirányítás folyamatban</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  status: state.loginReducer.status,
});
const LoginRedirect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedLoginRedirect);
export default LoginRedirect;
