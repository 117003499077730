import axios from "axios";
import { POI_ACTIONS } from "../constants/action-types";

export const getAllPoi = () => {
  return async (dispatch) => {
    dispatch({ type: POI_ACTIONS.POIS_LOADING });
    await axios
      .get("/1.0.0/poi", {})
      .then((response) => {
        dispatch({
          type: POI_ACTIONS.POIS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI_ACTIONS.POIS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getOnePoi = (poiId) => {
  return async (dispatch) => {
    dispatch({ type: POI_ACTIONS.POIS_LOADING });
    await axios
      .get("/1.0.0/poi/find/" + poiId, {})
      .then((response) => {
        dispatch({
          type: POI_ACTIONS.POIS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI_ACTIONS.POIS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const createPoi = (params) => {
  return async (dispatch) => {
    dispatch({ type: POI_ACTIONS.POI_REGISTER_START });
    await axios
      .post("/1.0.0/poi/create", params, {})
      .then((response) => {
        dispatch({
          type: POI_ACTIONS.POI_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI_ACTIONS.POI_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyPoi = (params) => {
  return async (dispatch) => {
    dispatch({ type: POI_ACTIONS.POI_REGISTER_START });
    await axios
      .put("/1.0.0/poi/modify", params, {})
      .then((response) => {
        dispatch({
          type: POI_ACTIONS.POI_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: POI_ACTIONS.POI_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deletePoi = (poiId) => {
  return async () => {
    await axios.delete("/1.0.0/poi/" + poiId).catch((err) => {
      console.log(err.message);
    });
  };
};
