import { TAG_ACTIONS } from "../constants/action-types";

export const openTagWithMessage = (message) => {
  return (dispatch) => {
    dispatch({ type: TAG_ACTIONS.OPEN_WITH_MESSAGE, payload: message });
  };
};

export const closeTag = () => {
  return (dispatch) => {
    dispatch({ type: TAG_ACTIONS.CLOSE });
  };
};
