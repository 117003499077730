import React, { Component } from "react";
import "./login.scss";
import { message } from "antd";
import { connect } from "react-redux";
import { ssoRedirect } from "../../actions/login";
const mapDispatchToProps = (dispatch) => {
  return {
    ssoRedirect: (token) => dispatch(ssoRedirect(token)),
  };
};
class ConnectedSSORedirect extends Component {
  componentDidMount = async () => {
    await this.SSO();
  };

  SSO = async () => {
    const token = new URLSearchParams(this.props.location.search).get(
      "auth_token"
    );
    await this.props.ssoRedirect(token);
    if (this.props.status) {
      this.props.history.push("/mol/layer");
    } else {
      message.error("Nem megfelelő user!", 5);
      this.props.history.push("/login")
    }
  };

  render() {
    return <></>;
  }
}
const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  status: state.loginReducer.status,
});
const SSORedirect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSSORedirect);
export default SSORedirect;
