import { VERSION_ACTIONS } from "../constants/action-types";

const initialState = {
  versions: [],
  message: "",
  saveStatus: false,
  status: false,
};

const versionReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERSION_ACTIONS.VERSION_LOADING:
      return Object.assign({}, state, {
        versions: [],
        status: false,
        message: "",
      });
    case VERSION_ACTIONS.VERSION_LOADED:
      return Object.assign({}, state, {
        versions: action.payload.version,
        status: true,
        message: "",
      });
    case VERSION_ACTIONS.VERSION_ERROR:
      return Object.assign({}, state, {
        versions: [],
        status: false,
        message: action.payload,
      });
    case VERSION_ACTIONS.VERSION_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case VERSION_ACTIONS.VERSION_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case VERSION_ACTIONS.VERSION_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default versionReducer;
