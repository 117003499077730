import FloatingBoard from "../components/FloatingBoard/floatingBoard";
import Layer from "../components/Layer/layer";
import Picture from "../components/Media/picture";
import Video from "../components/Media/video";
import Poi from "../components/POI/poi";
import Version from "../components/version/version";

export const routes = [
  {
    path: "/mol/layer",
    component: Layer,
  },
  {
    path: "/mol/poi",
    component: Poi,
  },
  {
    path: "/mol/picture",
    component: Picture,
  },
  {
    path: "/mol/video",
    component: Video,
  },
  {
    path: "/mol/floating-board",
    component: FloatingBoard,
  },
  {
    path: "/mol/version-history",
    component: Version,
  },
];
