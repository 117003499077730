import { POSITION_ACTIONS } from "../constants/action-types";

const initialState = {
  position: [],
  status: false,
  message: "",
  saveStatus: false,
};

const positionReducer = (state = initialState, action) => {
  switch (action.type) {
    case POSITION_ACTIONS.POSITIONS_LOADING:
      return Object.assign({}, state, {
        position: [],
        status: false,
        message: "",
      });
    case POSITION_ACTIONS.POSITIONS_LOADED:
      return Object.assign({}, state, {
        position: action.payload.position,
        status: true,
        message: "",
      });
    case POSITION_ACTIONS.POSITIONS_ERROR:
      return Object.assign({}, state, {
        position: [],
        status: false,
        message: action.payload,
      });
    case POSITION_ACTIONS.POSITION_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case POSITION_ACTIONS.POSITION_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case POSITION_ACTIONS.POSITION_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default positionReducer;
