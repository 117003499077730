import axios from "axios";
import { LAYER_ACTIONS } from "../constants/action-types";

export const getAllLayer = () => {
  return async (dispatch) => {
    dispatch({ type: LAYER_ACTIONS.LAYERS_LOADING });
    await axios
      .get("/1.0.0/layer", {})
      .then((response) => {
        dispatch({
          type: LAYER_ACTIONS.LAYERS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LAYER_ACTIONS.LAYERS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getOneLayer = (layerId) => {
  return async (dispatch) => {
    dispatch({ type: LAYER_ACTIONS.LAYER_LOADING });
    await axios
      .get("/1.0.0/layer/find/" + layerId, {})
      .then((response) => {
        dispatch({
          type: LAYER_ACTIONS.LAYER_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LAYER_ACTIONS.LAYER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const createLayer = (params) => {
  return async (dispatch) => {
    dispatch({ type: LAYER_ACTIONS.LAYER_REGISTER_START });
    await axios
      .post("/1.0.0/layer/create", params, {})
      .then((response) => {
        dispatch({
          type: LAYER_ACTIONS.LAYER_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LAYER_ACTIONS.LAYER_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const modifyLayer = (params) => {
  return async (dispatch) => {
    dispatch({ type: LAYER_ACTIONS.LAYER_REGISTER_START });
    await axios
      .put("/1.0.0/layer/modify", params, {})
      .then((response) => {
        dispatch({
          type: LAYER_ACTIONS.LAYER_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LAYER_ACTIONS.LAYER_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const createLayerIcon = (layerId, params) => {
  return async (dispatch) => {
    dispatch({ type: LAYER_ACTIONS.LAYER_ICON_REGISTER_START });
    await axios
      .put("/1.0.0/layer/create/icon/" + layerId, params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: LAYER_ACTIONS.LAYER_ICON_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LAYER_ACTIONS.LAYER_ICON_REGISTER_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const deleteLayerIcon = (layerId) => {
  return async () => {
    await axios.delete("/1.0.0/layer/icon/" + layerId).catch((err) => {
      console.log(err.message);
    });
  };
};

export const deleteLayer = (layerId) => {
  return async () => {
    await axios.delete("/1.0.0/layer/" + layerId).catch((err) => {
      console.log(err.message);
    });
  };
};
