import { LAYER_ACTIONS } from "../constants/action-types";

const initialState = {
  layers: [],
  layer: {},
  status: false,
  message: "",
  saveStatus: false,
  iconSaveStatus: false,
};

const layerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAYER_ACTIONS.LAYERS_LOADING:
      return Object.assign({}, state, {
        layers: [],
        status: false,
        message: "",
      });
    case LAYER_ACTIONS.LAYERS_LOADED:
      return Object.assign({}, state, {
        layers: action.payload.layer,
        status: true,
        message: "",
      });
    case LAYER_ACTIONS.LAYERS_ERROR:
      return Object.assign({}, state, {
        layers: [],
        status: false,
        message: action.payload,
      });
    case LAYER_ACTIONS.LAYER_LOADING:
      return Object.assign({}, state, {
        layer: {},
        status: false,
        message: "",
      });
    case LAYER_ACTIONS.LAYER_LOADED:
      return Object.assign({}, state, {
        layer: action.payload.layer,
        status: true,
        message: "",
      });
    case LAYER_ACTIONS.LAYER_ERROR:
      return Object.assign({}, state, {
        layer: [],
        status: false,
        message: action.payload,
      });
    case LAYER_ACTIONS.LAYER_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case LAYER_ACTIONS.LAYER_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case LAYER_ACTIONS.LAYER_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case LAYER_ACTIONS.LAYER_ICON_REGISTER_START:
      return Object.assign({}, state, {
        iconSaveStatus: false,
      });
    case LAYER_ACTIONS.LAYER_ICON_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        iconSaveStatus: true,
      });
    case LAYER_ACTIONS.LAYER_ICON_REGISTER_ERROR:
      return Object.assign({}, state, {
        iconSaveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default layerReducer;
