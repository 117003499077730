import { message } from "antd";
import axios from "axios";
import { FLOATING_BOARD_ACTIONS } from "../constants/action-types";

export const getAllFloatingBoard = () => {
  return async (dispatch) => {
    dispatch({ type: FLOATING_BOARD_ACTIONS.FLOATING_BOARDS_LOADING });
    await axios
      .get("/1.0.0/floating-board", {})
      .then((response) => {
        dispatch({
          type: FLOATING_BOARD_ACTIONS.FLOATING_BOARDS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FLOATING_BOARD_ACTIONS.FLOATING_BOARDS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getOneFloatingBoard = (fBoardId) => {
  return async (dispatch) => {
    dispatch({ type: FLOATING_BOARD_ACTIONS.FLOATING_BOARDS_LOADING });
    await axios
      .get("/1.0.0/floating-board/find/" + fBoardId, {})
      .then((response) => {
        dispatch({
          type: FLOATING_BOARD_ACTIONS.FLOATING_BOARDS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FLOATING_BOARD_ACTIONS.FLOATING_BOARDS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const createFloatingBoard = (params) => {
  return async (dispatch) => {
    dispatch({ type: FLOATING_BOARD_ACTIONS.FLOATING_BOARD_REGISTER_START });
    await axios
      .post("/1.0.0/floating-board/create", params, {})
      .then((response) => {
        dispatch({
          type: FLOATING_BOARD_ACTIONS.FLOATING_BOARD_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FLOATING_BOARD_ACTIONS.FLOATING_BOARD_REGISTER_ERROR,
          payload: err.response.data,
        });
        if (err.response.data.code === 4001) {
          message.error("Már elérted az aktiválható táblák mennyiségét!");
        }
      });
  };
};

export const modifyFloatingBoard = (params) => {
  return async (dispatch) => {
    dispatch({ type: FLOATING_BOARD_ACTIONS.FLOATING_BOARD_REGISTER_START });
    await axios
      .put("/1.0.0/floating-board/modify", params, {})
      .then((response) => {
        dispatch({
          type: FLOATING_BOARD_ACTIONS.FLOATING_BOARD_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FLOATING_BOARD_ACTIONS.FLOATING_BOARD_REGISTER_ERROR,
          payload: err.response.data,
        });
        if (err.response.data.code === 4001) {
          message.error("Már elérted az aktiválható táblák mennyiségét!");
        }
      });
  };
};

export const deleteFloatingBoard = (fBoardId) => {
  return async () => {
    await axios.delete("/1.0.0/floating-board/" + fBoardId).catch((err) => {
      console.log(err.message);
    });
  };
};
