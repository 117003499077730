import {
  Switch,
  Form,
  Col,
  Row,
  Input,
  message,
  Button,
  Space,
  Table,
  Popconfirm,
  Modal,
  Tooltip,
  // Select,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined, DeleteFilled, EditOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createLayer,
  getAllLayer,
  getOneLayer,
  modifyLayer,
  deleteLayer,
  createLayerIcon,
} from "../../actions/layer";
import logo from "../../static/logo.808190e0791c96c059ba.png";
import NoPicture from "../../static/259987.png";
const mapDispatchToProps = (dispatch) => {
  return {
    getAllLayer: () => dispatch(getAllLayer()),
    getOneLayer: (layerId) => dispatch(getOneLayer(layerId)),
    createLayer: (params) => dispatch(createLayer(params)),
    modifyLayer: (params) => dispatch(modifyLayer(params)),
    deleteLayer: (layerId) => dispatch(deleteLayer(layerId)),
    createLayerIcon: (layerId, params) =>
      dispatch(createLayerIcon(layerId, params)),
  };
};

class Connectedlayer extends Component {
  formRef = React.createRef();
  state = {
    layers: [],
    name: "",
    layerindex: null,
    active: false,
    isMain: false,
    isMol: false,
    statusID: null,
    layerID: null,
    file: [],
    modify: false,
    loaded: false,
    selectedId: null,
    modalVisible: false,
    deletePic: false,
    modifyPic: false,
    engName: "",
    huId: null,
    engId: null,
  };

  componentDidMount = async () => {
    await this.getAllLayer();
  };

  getAllLayer = async () => {
    await this.props.getAllLayer();
    if (this.props.status) {
      this.setState({
        layers: this.props.layers,
        loaded: true,
      });
    }
  };

  getOneLayer = async (layerId) => {
    await this.props.getOneLayer(layerId);
  };

  createLayer = async () => {
    const toSend = {
      id: this.state.modify ? this.state.selectedId : null,
      name: this.state.name,
      engName: this.state.engName,
      layerindex: this.state.layerindex,
      active: this.state.active,
      isMain: this.state.isMain,
      isMol: this.state.isMol,
      //////////////////////////////////////////////// HA EGYSZERRE AKARJUK A MÓDOSÍTÁSOKAT PUBLIKÁLNI AKKOR STATIKUSAN 2-es ARCHÍV STÁTUSZÚ AZ ÉRTÉK///////////////////////////////////////////////////////////////////
      statusID: 2 /*this.state.statusID*/,
      deletePic: this.state.modify ? this.state.deletePic : null,
      modifyPic: this.state.modify ? this.state.modifyPic : null,
      huId: this.state.huId,
      engId: this.state.engId,
    };
    const indexcheck = this.props.layers.filter(
      (layer) => layer.layerindex === parseInt(this.state.layerindex)
    );
    if (
      (this.state.modify &&
        this.props.layer.layerindex !== parseInt(this.state.layerindex) &&
        indexcheck.length > 0) ||
      (!this.state.modify && indexcheck.length > 0)
    ) {
      message.error("Ez az index szám már foglalt. Adjon meg egy másikat!");
    } else {
      this.state.modify
        ? await this.props.modifyLayer(toSend)
        : await this.props.createLayer(toSend);
      if (this.props.saveStatus) {
        message.success("Sikeres mentés");
        if (this.state.file.length > 0 && this.state.modify) {
          const formData = new FormData();
          if (this.state.file.length > 0) {
            formData.append("file", this.state.file[0]);
          }
          await this.props.createLayerIcon(this.state.selectedId, formData);
          if (this.props.iconSaveStatus) {
            message.success("Sikeres icon mentés");
          } else {
            message.error("Hiba az icon mentése közben");
          }
        }
        await this.getAllLayer();
        this.cancelModifyModal();
      } else {
        message.error("Hiba történt a mentés közben");
      }
    }
  };

  deleteLayerIcon = async () => {
    await this.props.deleteLayerIcon(this.state.selectedId);
  };

  deleteLayer = async (record) => {
    await this.props.deleteLayer(record.id);
    await this.getAllLayer();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeFile = () => {
    this.setState({ file: [], deletePic: true });
  };

  beforeUpload = (file) => {
    if (file.size / 1024 > 300) {
      this.setState({ file: [] });
      message.error("Túl nagy file (max 300KB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          this.setState({ file: [file], modifyPic: true });
        };
      };
    }
    return false;
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleChangeActive = (event) => {
    this.setState({ active: event });
  };

  handleChangeIsMain = (event) => {
    this.setState({ isMain: event });
  };

  handleChangeIsMol = (event) => {
    this.setState({ isMol: event });
  };

  showModifyModal = (record) => {
    this.getOneLayer(record.id);
    this.setState({
      modify: true,
      selectedId: record.id,
      modalVisible: true,
      name: record.name[0].name,
      layerindex: record.layerindex,
      active: record.active,
      isMain: record.isMain,
      isMol: record.isMol,
      //////////////////////////////////////////////// HA EGYSZERRE AKARJUK A MÓDOSÍTÁSOKAT PUBLIKÁLNI AKKOR STATIKUSAN 2-es ARCHÍV STÁTUSZÚ AZ ÉRTÉK///////////////////////////////////////////////////////////////////
      statusID: 2 /* record.status.id*/,
      engName: record.name[1].name,
      huId: record.name[0].id,
      engId: record.name[1].id,
    });
    this.formRef.current.setFieldsValue({
      name: record.name[0].name,
      engName: record.name[1].name,
      layerindex: record.layerindex,
      active: record.active,
      isMain: record.isMain,
      isMol: record.isMol,
      statusID: record.status.name,
    });
  };

  cancelModifyModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      modify: false,
      selectedId: null,
      modalVisible: false,
      name: "",
      layerindex: null,
      active: false,
      isMain: false,
      isMol: false,
      statusID: null,
      deletePic: false,
      modifyPic: false,
      engName: "",
      huId: null,
      engId: null,
    });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  onChangeStatusType = (event) => {
    this.setState({
      statusID: event,
    });
  };

  render() {
    const columns = [
      {
        title: "Ikon",
        key: "layerPictue",
        onCell: () => {
          return {
            style: { textAlign: "center", verticalAlign: "middle" },
          };
        },
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.icon ? (
                <img
                  src={record.icon}
                  alt="layerPicture"
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50% 50% 50% 50%",
                  }}
                />
              ) : (
                <img
                  src={NoPicture}
                  alt="nopic"
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50% 50% 50% 50%",
                  }}
                />
              )}
            </Space>
          );
        },
      },
      {
        title: "Név",
        key: "layerName",
        render: (text, record) => {
          return <div>{record.name ? record.name[0]?.name : ""}</div>;
        },
      },
      {
        title: "Index",
        key: "layerIndex",
        dataIndex: "layerindex",
      },
      {
        title: "Állapot",
        key: "active",
        render: (text, record) => {
          return (
            <div
              style={{
                width: "1.1vw",
                height: "1.1vw",
                borderRadius: "50%",
                backgroundColor:
                  record.active === null
                    ? "gray"
                    : record.active === true
                    ? "green"
                    : "gray",
              }}
            />
          );
        },
      },
      {
        title: "Státusz",
        key: "status",
        render: (text, record) => {
          return record.status
            ? record?.status.name === "archived"
              ? "Vázlat"
              : "Publikált"
            : [];
        },
      },
      {
        title: "",
        key: "isMain",
        render: (text, record) => {
          return <div>{record.isMain ? "Main" : ""}</div>;
        },
      },
      {
        title: "",
        key: "isMol",
        render: (text, record) => {
          return (
            <div>
              {record.isMol ? (
                <img src={logo} alt="logo" style={{ maxWidth: "5em" }} />
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        title: "Módosítás",
        key: "modify",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Button
                onClick={() => this.showModifyModal(record)}
                type="primary"
              >
                <EditOutlined />
              </Button>
            </Space>
          );
        },
      },
      {
        title: "Törlés",
        key: "delete",
        render: (text, record) => {
          return (
            <Popconfirm
              title="Biztosan törölni akarod ezt a layert?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => this.deleteLayer(record)}
            >
              <Button danger type="primary">
                <DeleteFilled />
              </Button>
            </Popconfirm>
          );
        },
      },
    ];
    return (
      <div>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>Layer</strong>
            </h1>
            <Row>
              <Row>
                <Col span={30} style={{ padding: "1em" }}>
                  <Button type="primary" onClick={this.showModal}>
                    Létrehozás
                  </Button>
                </Col>
              </Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  rowKey="id"
                  dataSource={this.state.layers}
                  locale="Nem található tartalom!"
                  loading={!this.state.loaded}
                  pagination={{
                    position: ["bottomCenter"],
                    pageSize: 10,
                    showSizeChanger: false,
                  }}
                  onChange={this.handleChangeTable}
                />
              </Col>
            </Row>
            <Modal
              visible={this.state.modalVisible}
              onCancel={this.cancelModifyModal}
              title={this.state.modify ? "Módosítás" : "Létrehozás"}
              maskClosable={false}
              forceRender
              centered
              footer={[
                <Button key="invite" type="primary" onClick={this.createLayer}>
                  {this.state.modify ? "Módosítás" : "Létrehozás"}
                </Button>,
                <Button key="cancel" onClick={this.cancelModifyModal}>
                  Mégsem
                </Button>,
              ]}
              width={"45em"}
            >
              <Row>
                <Col span={24} style={{ padding: "1em" }}>
                  <Form
                    ref={this.formRef}
                    name="register"
                    onFinish={this.createLayer}
                    scrollToFirstError
                  >
                    <Row>
                      <Col span={12} style={{ padding: "0.5em" }}>
                        <Tooltip title="Max 255 karakter!">
                          <Form.Item
                            name="name"
                            label="Magyar név"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                            ]}
                          >
                            <Input
                              name="name"
                              value={this.state.name}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col span={6} style={{ padding: "0.5em" }}>
                        <Tooltip title="Max 255 karakter!">
                          <Form.Item
                            name="engName"
                            label="Angol név"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                            ]}
                          >
                            <Input
                              name="engName"
                              value={this.state.engName}
                              onChange={this.handleChange}
                              style={{ width: 200 }}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Form.Item
                          name="isMain"
                          label="Main"
                          rules={[
                            {
                              required: true,
                              message: "Mező kitöltése kötelező!",
                            },
                          ]}
                        >
                          <Switch
                            name="isMain"
                            checked={this.state.isMain}
                            onChange={this.handleChangeIsMain}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Form.Item
                          name="isMol"
                          label="MOL"
                          rules={[
                            {
                              required: true,
                              message: "Mező kitöltése kötelező!",
                            },
                          ]}
                        >
                          <Switch
                            name="isMol"
                            checked={this.state.isMol}
                            onChange={this.handleChangeIsMol}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8} style={{ padding: "0.5em" }}>
                        <Form.Item
                          name="active"
                          label="Aktív"
                          rules={[
                            {
                              required: true,
                              message: "Mező kitöltése kötelező!",
                            },
                          ]}
                        >
                          <Switch
                            name="active"
                            checked={this.state.active}
                            onChange={this.handleChangeActive}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6} style={{ padding: "0.5em" }}>
                        <Tooltip title="Csak szám!">
                          <Form.Item
                            name="layerindex"
                            label="Index"
                            rules={[
                              {
                                required: true,
                                message: "Mező kitöltése kötelező!",
                              },
                              {
                                pattern: /^[0-9]*$/,
                                message: "Csak számok elfogadottak",
                              },
                            ]}
                          >
                            <Input
                              name="layerindex"
                              value={this.state.layerindex}
                              onChange={this.handleChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col span={12} style={{ padding: "0.5em" }}>
                        {/* <Form.Item
                          name="statusID"
                          label="Státusz"
                          rules={[
                            {
                              required: true,
                              message: "Mező kitöltése kötelező!",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "12em" }}
                            value={this.state.statusID}
                            onChange={(event) => this.onChangeStatusType(event)}
                          >
                            <Select.Option key={1} value={1}>
                              Publikált
                            </Select.Option>
                            <Select.Option key={2} value={2}>
                              Archivált
                            </Select.Option>
                          </Select>
                        </Form.Item> */}
                      </Col>
                    </Row>
                    {this.state.modify ? (
                      <Row>
                        <Col span={6} style={{ padding: "0.5em" }}></Col>
                        <Col span={12} style={{ padding: "0.5em" }}>
                          <Form.Item
                            name="file"
                            label=""
                            rules={[
                              {
                                required: false,
                                message: "Kérem adjon meg egy fájlt!",
                              },
                            ]}
                          >
                            <Tooltip title="Max 300 KB, jpg|jpeg|png|gif|PNG|JPG|JPEG típusú kép!">
                              <Dragger
                                name="file"
                                multiple={false}
                                onRemove={this.removeFile}
                                accept=".png,.jpg,.jpeg,.gif"
                                beforeUpload={this.beforeUpload}
                                fileList={this.state.file}
                              >
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Képfeltöltés</p>
                              </Dragger>
                            </Tooltip>
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : (
                      []
                    )}
                  </Form>
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layers: state.layerReducer.layers,
  layer: state.layerReducer.layer,
  status: state.layerReducer.status,
  saveStatus: state.layerReducer.saveStatus,
  iconSaveStatus: state.layerReducer.iconSaveStatus,
});

const Layer = connect(mapStateToProps, mapDispatchToProps)(Connectedlayer);
export default Layer;
