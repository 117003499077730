import { POI_ACTIONS } from "../constants/action-types";

const initialState = {
  poi: [],
  status: false,
  message: "",
  saveStatus: false,
};

const poiReducer = (state = initialState, action) => {
  switch (action.type) {
    case POI_ACTIONS.POIS_LOADING:
      return Object.assign({}, state, {
        poi: [],
        status: false,
        message: "",
      });
    case POI_ACTIONS.POIS_LOADED:
      return Object.assign({}, state, {
        poi: action.payload.poi,
        status: true,
        message: "",
      });
    case POI_ACTIONS.POIS_ERROR:
      return Object.assign({}, state, {
        poi: [],
        status: false,
        message: action.payload,
      });
    case POI_ACTIONS.POI_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case POI_ACTIONS.POI_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case POI_ACTIONS.POI_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default poiReducer;
