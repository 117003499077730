import React, { Component } from "react";
import "./login.scss";
import axios from "axios";

class SSOLogin extends Component {
  componentDidMount = async () => {
    await this.getData();
  };

  state = {
    context: "",
    authenticated: false,
  };

  getData = async () => {
    await axios
      .get("1.0.0/users/sso/post")
      .then((response) => {
        this.setState({
          context: response.data.context,
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      this.state.context !== "" && (
        <div>
          <form
            id="saml-form"
            method="post"
            action={(window.window.location.href = this.state.context)}
            autoComplete="off"
          >
            <input
              type="hidden"
              name="SAMLRequest"
              id="SAMLRequest"
              value={this.state.context}
            />
          </form>
          <script type="text/javascript">{document.forms[0].submit()}</script>
        </div>
      )
    );
  }
}

export default SSOLogin;
