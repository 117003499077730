import React, { Component } from "react";
import { Button, message, Row } from "antd";
import "./login.scss";
import { connect } from "react-redux";
import { login } from "../../actions/login";
import { openTagWithMessage } from "../../actions/tag";

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(login(username, password)),
    openTagWithMessage: (messageToSet) =>
      dispatch(openTagWithMessage(messageToSet)),
  };
};
class ConnectedLogin extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    document.body.style = "background: #ffffff; !important";
  }

  state = {
    username: "",
    password: "",
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFormSubmit = async () => {
    await this.props.login(this.state.username, this.state.password);
    if (this.props.status) {
      this.props.history.push("/mol/layer");
    } else {
      message.error("Nem megfelelő felhasználónév és jelszó páros!", 5);
      this.formRef.current.resetFields();
      this.setState({ username: "", password: "" });
    }
  };

  initPostRequest = async () => {
    window.location.href = `/sso/post`;
  };

  render() {
    return (
      <div className="login-page-container">
        <Row>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: "100%" }}
            onClick={() => this.initPostRequest()}
          >
            SSO LOGIN
          </Button>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  status: state.loginReducer.status,
});

const Login = connect(mapStateToProps, mapDispatchToProps)(ConnectedLogin);
export default Login;
