import React, { Component } from "react";
import { connect } from "react-redux";
import {
  message,
  Card,
  Row,
  Col,
  Button,
  Popconfirm,
  Form,
  Modal,
  Input,
  Select,
  Tooltip,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import {
  addVideo,
  deleteVideo,
  getAllVideo,
  modifyVideoName,
  uploadCoverPicToVideo,
  uploadVideo,
} from "../../actions/video";
import { DeleteOutlined, EditOutlined, InboxOutlined } from "@ant-design/icons";
import pic from "../../static/149125.png";
import { getAllPoi } from "../../actions/poi";
import { getAllFloatingBoard } from "../../actions/floatingBoard";
const mapDispatchToProps = (dispatch) => {
  return {
    getAllVideo: () => dispatch(getAllVideo()),
    uploadVideo: (file) => dispatch(uploadVideo(file)),
    addVideo: (params) => dispatch(addVideo(params)),
    deleteVideo: (videoId) => dispatch(deleteVideo(videoId)),
    modifyVideoName: (params) => dispatch(modifyVideoName(params)),
    getAllPoi: () => dispatch(getAllPoi()),
    getAllFloatingBoard: () => dispatch(getAllFloatingBoard()),
    uploadCoverPicToVideo: (id, file) =>
      dispatch(uploadCoverPicToVideo(id, file)),
  };
};
const { Meta } = Card;
class ConnectedVideo extends Component {
  formRef = React.createRef();
  state = {
    videos: [],
    file: [],
    coverPicFile: [],
    modalVisible: false,
    name: "",
    poiId: null,
    fBoardId: null,
    modify: false,
    selectedId: null,
  };
  componentDidMount = async () => {
    await this.getVideos();
    await this.props.getAllFloatingBoard();
    await this.props.getAllPoi();
  };

  getVideos = async () => {
    await this.props.getAllVideo();
    if (this.props.status) {
      this.setState({
        videos: this.props.videos,
      });
    }
  };

  createVideo = async () => {
    const formData = new FormData();
    if (this.state.file.length > 0) {
      formData.append("file", this.state.file[0]);
    }
    await this.props.uploadVideo(formData);
    if (this.props.saveStatus) {
      await this.uploadCoverPicToVideo(this.props.message.video.id);
      message.success("Sikeres mentés");
      await this.getVideos();
      this.closeModal();
    } else {
      message.error("Hiba a video mentése közben", 5);
    }
  };

  uploadCoverPicToVideo = async (videoId) => {
    const formData = new FormData();
    if (this.state.coverPicFile.length > 0) {
      formData.append("file", this.state.coverPicFile[0]);
    }
    await this.props.uploadCoverPicToVideo(videoId, formData);
    if (this.props.saveStatus) {
      message.success("Sikeres mentés");
    } else {
      message.error("Hiba a kép mentése közben", 5);
    }
  };

  modifyVideoName = async () => {
    let close = false;
    const toSend = {
      id: this.state.selectedId,
      name: this.state.name,
    };
    await this.props.modifyVideoName(toSend);
    if (this.props.saveStatus) {
      close = true;
      if (this.state.coverPicFile.length > 0) {
        await this.uploadCoverPicToVideo(this.state.selectedId);
      }
      if (this.state.poiId) {
        const addDataToSend = {
          videoID: this.state.selectedId,
          poiID: this.state.poiId,
        };
        await this.props.addVideo(addDataToSend);
        if (this.props.addSaveStatus) {
          message.success("Sikeres mentés");
          close = true;
        } else {
          close = false;
          message.error("Hiba a mentés közben");
        }
      }
      message.success("Sikeres mentés");
      if (close) {
        await this.getVideos();
        this.closeModal();
      }
    } else {
      message.error("Hiba a video mentése közben");
    }
  };

  deleteVideo = async (record) => {
    await this.props.deleteVideo(record.id);
    await this.getVideos();
  };

  renderPhotos = (source) => {
    return source.map((video) => {
      return (
        <Card
          style={{ maxWidth: "10em", margin: "1em" }}
          cover={
            <img
              alt={video.coverPic}
              src={video.coverPic ? video.coverPic : pic}
            />
          }
          actions={[
            <EditOutlined
              key="edit"
              onClick={() => this.showModifyModal(video)}
            />,
            <Popconfirm
              title="Biztosan törölni akarod ezt a videót?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => this.deleteVideo(video)}
            >
              <DeleteOutlined key="delete" />,
            </Popconfirm>,
          ]}
        >
          <Meta title={video.name} />
        </Card>
      );
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeFile = () => {
    this.setState({ file: [] });
  };

  beforeUpload = (file) => {
    if (file.size / 1024 / 1024 > 100) {
      this.setState({ file: [] });
      message.error("Túl nagy file (max 100MB)");
    } else {
      this.setState({ file: [file] });
    }
    return false;
  };

  removeCoverPicFile = () => {
    this.setState({ coverPicFile: [] });
  };

  beforeCoverPicUpload = (file) => {
    if (file.size / 1024 / 1024 > 5) {
      this.setState({ coverPicFile: [] });
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            this.setState({ coverPicFile: [] });
          } else {
            this.setState({ coverPicFile: [file] });
          }
        };
      };
    }
    return false;
  };

  handleChangePoi = (event, record) => {
    this.setState({ poiId: event });
  };

  handleChangeFloatingBoard = (event, record) => {
    this.setState({ fBoardId: event });
  };

  showModifyModal = (record) => {
    const poiNames = this.props.poi?.filter((poi) =>
      poi.id === record.poi?.id ? poi.poiName[0] : ""
    );
    this.setState({
      modalVisible: true,
      modify: true,
      selectedId: record.id,
      name: record.name,
      poiId: record?.poi?.id,
      fBoardId: record?.floatingBoard?.id,
    });
    this.formRef.current.setFieldsValue({
      name: record.name,
      poiId: poiNames[0]?.poiName[0]?.name,
      fBoardId: record.floatingBoard?.name,
    });
  };
  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  closeModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      modalVisible: false,
      modify: false,
      selectedId: null,
      name: "",
      poiId: null,
      fBoardId: null,
    });
    this.removeFile();
    this.removeCoverPicFile();
  };

  render() {
    return (
      <>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>Videók</strong>
            </h1>
            <Row>
              <Row>
                <Col span={30} style={{ padding: "1em" }}>
                  <Button type="primary" onClick={this.showModal}>
                    Létrehozás
                  </Button>
                </Col>
              </Row>
            </Row>
            <Row>{this.renderPhotos(this.state.videos)}</Row>
          </Col>
        </Row>
        <Modal
          visible={this.state.modalVisible}
          onCancel={this.closeModal}
          title={this.state.modify ? "Módosítás" : "Létrehozás"}
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Button
              key="createPic"
              type="primary"
              onClick={
                this.state.modify ? this.modifyVideoName : this.createVideo
              }
            >
              {this.state.modify ? "Módosítás" : "Létrehozás"}
            </Button>,
            <Button key="cancel" onClick={this.closeModal}>
              Mégsem
            </Button>,
          ]}
          width={"45em"}
        >
          <Form
            ref={this.formRef}
            name="register"
            onFinish={
              this.state.modify ? this.modifyVideoName : this.createVideo
            }
            scrollToFirstError
          >
            {!this.state.modify ? (
              <>
                <Row>
                  <Col span={10} style={{ padding: "0.5em" }}>
                    <Tooltip title="Max 100MB-os mp4|mp4p|mp4v formátumú file!">
                      <Form.Item
                        name="file"
                        rules={[
                          {
                            required: true,
                            message: "Kérem adjon meg egy fájlt!",
                          },
                        ]}
                      >
                        <Dragger
                          name="file"
                          multiple={false}
                          onRemove={this.removeFile}
                          accept=".mp4"
                          beforeUpload={this.beforeUpload}
                          fileList={this.state.file}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Vidófeltöltés</p>
                        </Dragger>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col span={4} style={{ padding: "0.5em" }}></Col>
                  <Col span={10} style={{ padding: "0.5em" }}>
                    <Tooltip title="Max FullHD és 5MB-nál kisebb jpg|jpeg|png|gif|PNG|JPG|JPEG formátumú file!">
                      <Form.Item
                        name="coverPicFile"
                        rules={[
                          {
                            required: true,
                            message: "Kérem adjon meg egy fájlt!",
                          },
                        ]}
                      >
                        <Dragger
                          name="file"
                          multiple={false}
                          onRemove={this.removeCoverPicFile}
                          accept=".png,.jpg,.jpeg,.gif"
                          beforeUpload={this.beforeCoverPicUpload}
                          fileList={this.state.coverPicFile}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Indexkép feltöltése</p>
                        </Dragger>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col span={6} style={{ padding: "0.5em" }}></Col>
                  <Col span={12} style={{ padding: "0.5em" }}>
                    <Tooltip title="Max FullHD és 5MB-nál kisebb jpg|jpeg|png|gif|PNG|JPG|JPEG formátumú file!">
                      <Form.Item
                        name="coverPicFile"
                        rules={[
                          {
                            required: false,
                            message: "Kérem adjon meg egy fájlt!",
                          },
                        ]}
                      >
                        <Dragger
                          name="file"
                          multiple={false}
                          onRemove={this.removeCoverPicFile}
                          accept=".png,.jpg,.jpeg,.gif"
                          beforeUpload={this.beforeCoverPicUpload}
                          fileList={this.state.coverPicFile}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Indexkép feltöltése</p>
                        </Dragger>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col span={14} style={{ padding: "0.5em" }}>
                    <Tooltip title="Max 255 karakter!">
                      <Form.Item
                        name="name"
                        label="Név"
                        rules={[
                          {
                            required: true,
                            message: "Mező kitöltése kötelező!",
                          },
                        ]}
                      >
                        <Input
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col span={10} style={{ padding: "0.5em" }}>
                    <Form.Item label="Poi" name="poiId">
                      <Select
                        style={{ width: "8vw" }}
                        value={this.state.poiId}
                        onChange={(event) => this.handleChangePoi(event)}
                      >
                        <Select.Option
                          key={9999999999999}
                          value={9999999999999}
                        >
                          Egyik sem
                        </Select.Option>
                        {this.props.poi?.map((poi) => {
                          return (
                            <Select.Option key={poi.id} value={poi.id}>
                              {poi.poiName[0]?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  videos: state.videoReducer.video,
  status: state.videoReducer.status,
  saveStatus: state.videoReducer.saveStatus,
  message: state.videoReducer.message,
  poi: state.poiReducer.poi,
  floatingBoard: state.floatingBoardsReducer.floatingBoard,
  addSaveStatus: state.videoReducer.addSaveStatus,
});

const Video = connect(mapStateToProps, mapDispatchToProps)(ConnectedVideo);
export default Video;
