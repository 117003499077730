export const LOGIN_ACTIONS = {
  TOKEN_LOADING: "TOKEN_LOADING",
  TOKEN_LOADED: "TOKEN_LOADED",
  TOKEN_ERROR: "TOKEN_ERROR",
  LOGOUT: "LOGOUT",
  API_KEY_LOADING: "API_KEY_LOADING",
  API_KEY_LOADED: "API_KEY_LOADED",
  API_KEY_ERROR: "API_KEY_ERROR",
  SSO_RERIRECT_LOADING: "SSO_RERIRECT_LOADING",
  SSO_RERIRECT_LOADED: "SSO_RERIRECT_LOADED",
  SSO_RERIRECT_ERROR: "SSO_RERIRECT_ERROR",
};

export const USERS_ACTIONS = {
  USERS_LOADING: "USERS_LOADING",
  USERS_LOADED: "USERS_LOADED",
  USERS_ERROR: "USERS_ERROR",
  USER_REGISTER_START: "USER_REGISTER_START",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_ERROR: "USER_REGISTER_ERROR",
};

export const TAG_ACTIONS = {
  OPEN_WITH_MESSAGE: "OPEN_WITH_MESSAGE",
  CLOSE: "CLOSE",
};

export const FLOATING_BOARD_ACTIONS = {
  FLOATING_BOARDS_LOADING: "FLOATING_BOARDS_LOADING",
  FLOATING_BOARDS_LOADED: "FLOATING_BOARDS_LOADED",
  FLOATING_BOARDS_ERROR: "FLOATING_BOARDS_ERROR",
  FLOATING_BOARD_REGISTER_START: "FLOATING_BOARD_REGISTER_START",
  FLOATING_BOARD_REGISTER_SUCCESS: "FLOATING_BOARD_REGISTER_SUCCESS",
  FLOATING_BOARD_REGISTER_ERROR: "FLOATING_BOARD_REGISTER_ERROR",
};

export const LAYER_ACTIONS = {
  LAYERS_LOADING: "LAYERS_LOADING",
  LAYERS_LOADED: "LAYERS_LOADED",
  LAYERS_ERROR: "LAYERS_ERROR",
  LAYER_LOADING: "LAYER_LOADING",
  LAYER_LOADED: "LAYER_LOADED",
  LAYER_ERROR: "LAYER_ERROR",
  LAYER_REGISTER_START: "LAYER_REGISTER_START",
  LAYER_REGISTER_SUCCESS: "LAYER_REGISTER_SUCCESS",
  LAYER_REGISTER_ERROR: "LAYER_REGISTER_ERROR",
  LAYER_ICON_REGISTER_START: "LAYER_ICON_REGISTER_START",
  LAYER_ICON_REGISTER_SUCCESS: "LAYER_ICON_REGISTER_SUCCESS",
  LAYER_ICON_REGISTER_ERROR: "LAYER_ICON_REGISTER_ERROR",
};

export const PICTURE_ACTIONS = {
  PICTURES_LOADING: "PICTURES_LOADING",
  PICTURES_LOADED: "PICTURES_LOADED",
  PICTURES_ERROR: "PICTURES_ERROR",
  PICTURE_REGISTER_START: "PICTURE_REGISTER_START",
  PICTURE_REGISTER_SUCCESS: "PICTURE_REGISTER_SUCCESS",
  PICTURE_REGISTER_ERROR: "PICTURE_REGISTER_ERROR",
  PICTURE_ADD_START: "PICTURE_ADD_START",
  PICTURE_ADD_SUCCESS: "PICTURE_ADD_SUCCESS",
  PICTURE_ADD_ERROR: "PICTURE_ADD_ERROR",
};

export const POI_ACTIONS = {
  POIS_LOADING: "POIS_LOADING",
  POIS_LOADED: "POIS_LOADED",
  POIS_ERROR: "POIS_ERROR",
  POI_REGISTER_START: "POI_REGISTER_START",
  POI_REGISTER_SUCCESS: "POI_REGISTER_SUCCESS",
  POI_REGISTER_ERROR: "POI_REGISTER_ERROR",
};

export const POSITION_ACTIONS = {
  POSITIONS_LOADING: "POSITIONS_LOADING",
  POSITIONS_LOADED: "POSITIONS_LOADED",
  POSITIONS_ERROR: "POSITIONS_ERROR",
  POSITION_REGISTER_START: "POSITION_REGISTER_START",
  POSITION_REGISTER_SUCCESS: "POSITION_REGISTER_SUCCESS",
  POSITION_REGISTER_ERROR: "POSITION_REGISTER_ERROR",
};

export const VIDEO_ACTIONS = {
  VIDEOS_LOADING: "VIDEOS_LOADING",
  VIDEOS_LOADED: "VIDEOS_LOADED",
  VIDEOS_ERROR: "VIDEOS_ERROR",
  VIDEO_REGISTER_START: "VIDEO_REGISTER_START",
  VIDEO_REGISTER_SUCCESS: "VIDEO_REGISTER_SUCCESS",
  VIDEO_REGISTER_ERROR: "VIDEO_REGISTER_ERROR",
  VIDEO_ADD_REGISTER_START: "VIDEO_ADD_REGISTER_START",
  VIDEO_ADD_REGISTER_SUCCESS: "VIDEO_ADD_REGISTER_SUCCESS",
  VIDEO_ADD_REGISTER_ERROR: "VIDEO_ADD_REGISTER_ERROR",
};

export const VERSION_ACTIONS = {
  VERSION_LOADING: "VERSION_LOADING",
  VERSION_LOADED: "VERSION_LOADED",
  VERSION_ERROR: "VERSION_ERROR",
  VERSION_REGISTER_START: "VERSION_REGISTER_START",
  VERSION_REGISTER_SUCCESS: "VERSION_REGISTER_SUCCESS",
  VERSION_REGISTER_ERROR: "VERSION_REGISTER_ERROR",
};
